<template>
  <div>
    <a-modal
      title="修改日期"
      :visible="visible"
      @ok="handleOk"
      @cancel="() => hideDialog()"
      centered
      okText="确认"
      cancelText="取消"
      :destroyOnClose="true"
    >
      <a-form-model layout="vertical" :model="form" ref="form">
        <a-form-model-item label="年份" prop="year">
          <a-input disabled width="100%" v-model="form.year"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="日期" prop="date">
          <a-input disabled width="100%" v-model="form.date"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="日期类型" prop="dateType">
          <a-select ref="select" :value="form.dateType" style="width: 100%" @change="handleSelectChange">
            <a-select-option :value="0"><holidays-tag :dateType="0" /></a-select-option>
            <a-select-option :value="1"><holidays-tag :dateType="1" /></a-select-option>
            <a-select-option :value="2"><holidays-tag :dateType="2" /></a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import HolidaysTag from './HolidaysTag';

export default {
  name: 'HolidaysModal',
  components: { HolidaysTag },
  data() {
    return {
      visible: false,
      form: {},
    };
  },
  methods: {
    // 开启弹窗事件
    showModal(params) {
      this.visible = true;
      this.form = clonedeep(params);
    },
    // 确认修改按钮事件
    handleOk() {
      const params = {
        id: this.form.id,
        date: this.form.date,
        dateType: this.form.dateType,
      };
      this.$apiManager.holidays.updateHolidays(params).then(() => {
        this.$message.success('修改成功');
        this.visible = false;
        this.$parent.listHolidays();
      });
    },
    hideDialog() {
      this.visible = false;
    },
    // 日期类型改变事件
    handleSelectChange(value) {
      this.form.dateType = value;
    },
    // 日期选择框改变事件
    handleDateChange(date, dateString) {
      if (date === null && dateString === '') {
        this.form.date = undefined;
      } else {
        this.form.date = date;
      }
    },
  },
};
</script>
