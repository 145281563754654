<template>
  <a-tag :color="dateType == 0 ? 'volcano' : dateType == 2 ? 'green' : 'geekblue'">{{
    dateType == 0 ? '未知' : dateType == 1 ? '工作日（上班）' : '节假日（休息）'
  }}</a-tag>
</template>

<script>
export default {
  name: 'HolidaysTag',
  props: ['dateType'],
};
</script>
