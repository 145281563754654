<template>
  <div class="content-layout">
    <div class="holidays-operations">
      <div style="display: flex" class="left" v-if="$hasPermissions(['rcs:date_information:list'])">
        <a-date-picker
          :format="showMode === 'year' ? 'YYYY' : 'YYYY-MM'"
          placeholder="请选择日期"
          mode="month"
          :value="searchDate"
          :open="openSearchDate"
          @panelChange="panelChange"
          @openChange="(status) => (openSearchDate = status)"
          @change="changeSearchYear"
        >
        </a-date-picker>
        <a-button class="btn" type="primary" @click="handleSearch">搜索</a-button>
      </div>
      <div
        class="right"
        v-if="$hasPermissions(['rcs:date_information:create']) || $hasPermissions(['rcs:date_information:delete'])"
      >
        <a-date-picker
          format="YYYY"
          placeholder="请选择年份"
          mode="year"
          :value="initOrDelYear"
          :open="openInitOrDelYear"
          @panelChange="
            (date) => {
              initOrDelYear = date;
              openInitOrDelYear = false;
            }
          "
          @openChange="(status) => (openInitOrDelYear = status)"
          @change="changeInitYear"
        />
        <a-popconfirm
          :visible="initPopVisible"
          okText="是"
          cancelText="否"
          title="当前年份已经存在数据，是否要初始化?"
          @confirm="
            () => {
              initPopVisible = false;
              init();
            }
          "
          @cancel="() => (initPopVisible = false)"
        >
          <a-button
            v-if="$hasPermissions(['rcs:date_information:create'])"
            class="btn"
            type="primary"
            @click="handleInit"
            >初始化</a-button
          >
        </a-popconfirm>
        <a-popconfirm
          :visible="delPopVisible"
          okText="是"
          cancelText="否"
          title="是否删除日期?"
          @confirm="onDelete"
          @cancel="() => (delPopVisible = false)"
        >
          <a-button v-if="$hasPermissions(['rcs:date_information:delete'])" class="btn" type="danger" @click="handleDel"
            >删除</a-button
          >
        </a-popconfirm>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :dataSource="tableData"
      :columns="this.$constRepository.holidays.columns"
      class="holidays-table"
      @change="tableChange"
      rowKey="id"
    >
      <template slot="date" slot-scope="scope">
        {{ scope }}
      </template>

      <template slot="createTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="dateType" slot-scope="scope, record">
        <span>
          <holidays-tag :dateType="scope" />
          <a-tag style="margin-left: 5px" color="red" v-if="record.holidayName">{{ record.holidayName }}</a-tag>
        </span>
      </template>
      <template slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:date_information:update'])"
          class="green-btn"
          ghost
          @click="handleEdit(scope)"
          >编辑</a-button
        >
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
    <holidays-modal ref="holidaysModal"></holidays-modal>
  </div>
</template>
<script>
import HolidaysModal from './comonents/HolidaysModal';
import HolidaysTag from './comonents/HolidaysTag';

export default {
  name: 'HolidaysManagement',
  components: { HolidaysModal, HolidaysTag },
  activated() {
    this.listHolidays();
  },
  data() {
    return {
      showMode: '',
      openInitOrDelYear: false,
      openSearchDate: false,
      initOrDelYear: undefined,
      searchDate: undefined,
      tableData: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      initPopVisible: false,
      delPopVisible: false,
      requestParam: {},
    };
  },
  methods: {
    panelChange(date, mode) {
      this.showMode = mode === 'year' ? 'year' : 'month';
      this.searchDate = date;
      this.openSearchDate = false;
    },
    tableChange(option, filters) {
      // eslint-disable-next-line radix
      this.requestParam.dateType = filters.dateType.length > 0 ? parseInt(filters.dateType[0]) : undefined;
      this.loadedPageData();
    },
    handleSearch() {
      if (this.searchDate) {
        if (this.showMode === 'year') {
          this.requestParam.year = this.searchDate.format('YYYY');
          this.requestParam.month = undefined;
        } else {
          this.requestParam.year = this.searchDate.format('YYYY');
          this.requestParam.month = this.searchDate.format('MM');
        }
      }
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    handleDel() {
      if (this.initOrDelYear) {
        this.delPopVisible = true;
      } else {
        this.$message.warning('请选择需删除年份！');
      }
    },
    changeSearchYear(date, dateString) {
      if (date === null && dateString === '') {
        this.searchDate = undefined;
        this.showMode = '';
        this.requestParam.year = undefined;
        this.requestParam.month = undefined;
      } else {
        this.searchDate = date;
      }
    },
    // 输入框改变时触发时间（包括清除按钮）
    changeInitYear(date, dateString) {
      if (date === null && dateString === '') {
        this.initOrDelYear = undefined;
      } else {
        this.initOrDelYear = date;
      }
    },
    // 打开编辑弹窗
    handleEdit(params) {
      this.$refs.holidaysModal.showModal(params);
    },
    // 确认删除事件
    onDelete() {
      this.$apiManager.holidays
        .deleteHolidays(this.initOrDelYear.format('YYYY'))
        .then(() => {
          this.initOrDelYear = undefined;
          this.$message.success('删除成功');
          this.loadedPageData();
        })
        .finally(() => {
          this.delPopVisible = false;
        });
    },
    // 初始化按钮事件
    async handleInit() {
      if (!this.initOrDelYear) {
        this.$message.warning('请选择初始化年份！');
        return;
      }
      const response = await this.$apiManager.holidays.getHolidays({ year: this.initOrDelYear.format('YYYY') });
      const hasYear = response.data.elements.length !== 0;
      if (hasYear) {
        this.initPopVisible = true;
      } else {
        this.init();
      }
    },
    init() {
      this.$apiManager.holidays.initHolidays({ year: this.initOrDelYear.format('YYYY') }).then(() => {
        this.$message.success('初始化成功');
        this.initOrDelYear = undefined;
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    async listHolidays() {
      const params = {
        year: this.requestParam.year,
        month: this.requestParam.month,
        dateType: this.requestParam.dateType,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.holidays.getHolidays(params);
      this.tableData = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      await this.listHolidays();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listHolidays();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;

  .holidays-operations {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;

    .btn {
      margin-left: 15px;
      width: 80px;
    }

    .right {
      display: flex;
    }
  }

  .holidays-table {
    margin-top: 1%;

    .green-btn {
      color: green;
      border: none;
    }
  }
  .pagination {
    margin-top: 3%;
  }
}
</style>
